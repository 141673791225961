import React from "react";
import "./App.css";
import ReactDOM from "react-dom";
import App from "./App";
import { NativeBaseProvider, extendTheme } from "native-base";
import reportWebVitals from "./reportWebVitals";
import MomentUtils from "@date-io/moment";
// import "bootstrap/dist/css/bootstrap.min.css";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
  },
  colors: {
    primary: {
      100: "#40C0CB",
      200: "#40C0CB",
      300: "#40C0CB",
      400: "##40C0CB",
      500: "##00A8C6",
      600: "#00A8C6",
      700: "#00A8C6",
      800: "#00A8C6",
      900: "#00A8C6",
    },
    secondary: {
      100: "#DDE0E4",
      200: "#BCBEC2",
      300: "#A0A2A4",
      400: "#838486",
      500: "#6D6E70",
      600: "#707072",
      700: "#4E4F50",
    },
    blue: {
      100: "#BCCBF9",
      200: "#9EB4F4",
      300: "#7593EF",
      400: "#567AE9",
      500: "#4169E1",
      600: "#5C53FD",
      700: "#4136FF",
    },
    green: {
      100: "#B5FFCE",
      200: "#9DFFBE",
      300: "#7FFDAA",
      400: "#70F49D",
      500: "#27B858",
      600: "#16B84D",
      700: "#0FAD45 ",
    },
    white: {
      100: "#fff",
      200: "#fff",
      300: "#fff",
      400: "#fff",
      500: "#fff",
      600: "#fff",
      700: "#fff ",
    },
    black: {
      100: "#CACCCF",
      200: "#B3B5B9",
      300: "#9E9FA1",
      400: "#818183",
      500: "#6D6E70",
      600: "#525457",
      700: "#37393C",
      900: "#000",
    },
  },
  // fonts: {
  //   heading: "Open Sans",
  //   body: "Open Sans",
  //   mono: "Open Sans",
  // },
  components: {
    Text: {
      baseStyle: (props) => {
        return {
          color: "#37393C",
        };
      },
    },
    Heading: {
      baseStyle: (props) => {
        return {
          color: "#37393C",
          fontWeight: "bold",
        };
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <NativeBaseProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <App />
      </MuiPickersUtilsProvider>
    </NativeBaseProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
