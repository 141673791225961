import React from "react";
import { useHistory } from "react-router";

import {
  Center,
  FormControl,
  VStack,
  Heading,
  Stack,
  Button,
  useToast,
  TextArea,
  Image,
} from "native-base";

const CustomTextArea = (props) => {
  return (
    <FormControl>
      <FormControl.Label>{props.label}</FormControl.Label>
      <TextArea {...props} fontSize="md" />
    </FormControl>
  );
};

export default function SignupQuestions() {
  const history = useHistory();
  const toast = useToast();

  const [experience, setExperience] = React.useState();
  const [goals, setGoals] = React.useState();

  const onNext = () => {
    if (!experience || !goals) {
      toast.show({
        title: "Fields cannot be empty!",
        status: "warning",
        placement: "top",
      });
    }

    history.push("/schedule-interview", {
      ...history.location.state,
      experience: experience,
      goals: goals,
    });
  };


  return (
    <div
      style={{
        paddingTop: 50,
        paddingBottom: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: "#0A3A5A",
      }}
    >
      <Center mb={5} bg="#0A3A5A">
        <Image
          source={{
            uri: "https://www.thegenuineproject.org/wp-content/uploads/2020/08/g-logo-1.png",
          }}
          style={{
            height: 50,
            width: 200,
          }}
          resizeMode="contain"
        />
      </Center>

      <Center w="100%">
        <VStack
          bg="white.500"
          p={[2, 5, 10]}
          py={10}
          w={["95%", "90%", "80%"]}
          rounded="sm"
        >
          <Center mb={10}>
            <Heading textAlign="center">QUESTIONAIRES</Heading>
          </Center>

          <Stack
            space={[1, 2, 5]}
            width={["90%", "85%", "50%"]}
            alignSelf="center"
          >
            <CustomTextArea
              label="List  goals you have in joining the Genuine Project Student Internship Programme Abroad of Summer 22’ in Ghana."
              value={goals}
              onChangeText={setGoals}
            />

            <CustomTextArea
              label="Give an example of a prior experience that displays adaptability abroad in a limited resource environment."
              value={experience}
              onChangeText={setExperience}
            />
          </Stack>

          <Center mt={10}>
            <Button onPress={onNext} colorScheme="green">
              NEXT
            </Button>
          </Center>
        </VStack>
      </Center>
    </div>
  );
}
