import React from "react";
import { useHistory } from "react-router";

export default function SubmitSuccess() {

   const history = useHistory();

  React.useEffect(() => {
      window.open("https://www.thegenuineproject.org", "_self");
  });

  return <div></div>;
}
