import React, { useState } from "react";
import { useHistory } from "react-router";

import { KeyboardDateTimePicker } from "@material-ui/pickers";
import * as moment from "moment";

import {
  FormControl,
  Input,
  Button,
  VStack,
  useToast,
  Center,
  Stack,
  Heading,
  Image,
  Spinner,
  Text,
} from "native-base";

import { FormGroup, Label, Input as RInput, FormText } from "reactstrap";

import { db, storage } from "../firebase-config";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

export default function ScheduleInterview() {
  const history = useHistory();
  const toast = useToast();

  const [selectedDate, handleDateChange] = useState(moment());
  const [selectedSecondDate, handleSelectedSecondDateChange] = useState(
    moment()
  );
  const [selectedThirdDate, handleSelectedThirdDateChange] = useState(moment());
  const [phoneNumber, setPhoneNumber] = useState();
  const [finshed, setFinshed] = useState(false);
  const [file, setFile] = useState();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [resume, setResume] = useState("");

  const userCollectionRef = collection(db, "users");

  var data = {
    service_id: "service_4ltzzl2",
    template_id: "template_38ja84v",
    user_id: "user_wszSKK5ypkUKILLiwVEHW",
    template_params: {
      subject: "Genuine Internship Application",
      name: history.location.state.name,
      email: history.location.state.email,
      date1: selectedDate.format("dddd, MMMM Do YYYY, h:mm:ss a"),
      date2: selectedSecondDate.format("dddd, MMMM Do YYYY, h:mm:ss a"),
      date3: selectedThirdDate.format("dddd, MMMM Do YYYY, h:mm:ss a"),
     
    },
  };

  var errorData = {
    service_id: "service_4ltzzl2",
    template_id: "template_38ja84v",
    user_id: "user_wszSKK5ypkUKILLiwVEHW",
    template_params: {
      subject: "Genuine Internship Application - Error Uploading Resume",
      name: history.location.state.name,
      email: history.location.state.email,
      date1: `phone number ${phoneNumber}`,
      date2: `name ${history.location.state.name}`,
      date3: `email ${history.location.state.email}`,
    },
  };

  const goToFinish = React.useCallback(() => {
    const timer = setTimeout(() => {
      return history.push("/submit-success");
    }, 6000);
    return () => clearTimeout(timer);
  }, [history]);

  const uploadFile = async (file) => {
    if (!file) return;

    const storageRef = ref(storage, `/files/${history.location.state.name}`);

    const uploadTask = uploadBytesResumable(storageRef, file);
    setLoading(true);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: "Error",
          text: `Could not upload file (${error.code})`,
          icon: "error",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          confirmButtonText: "Close and try again",
        }).then((result) => {
          // send email to admin with error
          if (result.isConfirmed) {
            sendErrorEmail();
          }
        });
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          addDoc(userCollectionRef, {
            ...history.location.state,
            selectedDate: selectedDate.format("dddd, MMMM Do YYYY, h:mm:ss a"),
            selectedSecondDate: selectedSecondDate.format(
              "dddd, MMMM Do YYYY, h:mm:ss a"
            ),
            selectedThirdDate: selectedThirdDate.format(
              "dddd, MMMM Do YYYY, h:mm:ss a"
            ),
            phoneNumber: phoneNumber,
            read: false,
            resume: url,
            dateApplied: new Date(),
          })
            .then(() => {
              sendEmail();
            })
            .then(() => {
              Swal.fire({
                title: "Success!",
                text: `Great!, Your details have been submitted successfully and an email has been sent to ${history.location.state.email}`,
                icon: "success",
                backdrop: `rgb(9,58,90,.5)`,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  setFinshed(true);
                  goToFinish();
                }
              });
            })
            .catch((error) => {
              // log error
              console.log(error);
              toast.show({
                title: "OPPS! Something went wrong",
                description: "Please try again",
                duration: 3000,
                status: "error",
                placement: "top",
              });
            });
        });
        // stop loading
        setLoading(false);
      }
    );
  };

  const createUser = async () => {
    await uploadFile(file);
  };

  const sendEmail = async () => {
    await fetch("https://api.emailjs.com/api/v1.0/email/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const sendErrorEmail = async () => {
    await fetch("https://api.emailjs.com/api/v1.0/email/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(errorData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onFinish = () => {
    if (!phoneNumber || !file) {
      toast.show({
        title: "Fields cannot be empty!",
        status: "warning",
        placement: "top",
      });
    } else {
      Swal.fire({
        title: "We are about to confirm your registration",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Confirm it!",
      }).then((result) => {
        if (result.isConfirmed) {
          createUser();
        }
      });
    }
  };

  if (finshed) {
    return (
      <div
        style={{
          paddingTop: 70,
          paddingBottom: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Center mb={5} h="80vh">
          <Image
            source={{
              uri: "https://www.thegenuineproject.org/wp-content/uploads/2020/08/g-logo-1.png",
            }}
            style={{
              height: 50,
              width: 200,
            }}
            resizeMode="contain"
          />

          <Heading
            color="white.300"
            fontFamily="consolas"
            fontWeight="500"
            mt={[1, 3, 5]}
            p={[4, 4, 0]}
            textAlign="center"
          >
            You have completed your application successfully!
          </Heading>
        </Center>
      </div>
    );
  }

  return (
    <div
      style={{
        paddingTop: 70,
        paddingBottom: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Center mb={5}>
        <Image
          source={{
            uri: "https://www.thegenuineproject.org/wp-content/uploads/2020/08/g-logo-1.png",
          }}
          style={{
            height: 50,
            width: 200,
          }}
          resizeMode="contain"
        />
      </Center>

      <Center w="100%">
        {loading ? (
          <VStack
            bg="white.500"
            p={[2, 5, 10]}
            py={10}
            w={["95%", "90%", "80%"]}
            rounded="sm"
            alignItems="center"
            justifyContent="center"
            mt={"25vh"}
          >
            <Spinner color="emerald.500" size="lg" />
            <Text fontSize="lg" fontWeight={"bold"}>
              {progress}%
            </Text>
          </VStack>
        ) : (
          <VStack
            bg="white.500"
            p={[2, 5, 10]}
            py={10}
            w={["95%", "90%", "80%"]}
            rounded="sm"
          >
            <Center mb={10}>
              <Heading textAlign="center">SCHEDULE YOUR INTERVIEW</Heading>
            </Center>

            <Stack
              space={[10]}
              width={["90%", "85%", "50%"]}
              alignSelf="center"
            >
              <KeyboardDateTimePicker
                ampm={false}
                label="Select First Choice Time"
                value={selectedDate}
                onChange={handleDateChange}
                onError={console.log}
                format="dddd, MMMM Do YYYY, h:mm:ss a"
                disablePast
              />

              <KeyboardDateTimePicker
                ampm={false}
                label="Select First Choice Time"
                value={selectedSecondDate}
                onChange={handleSelectedSecondDateChange}
                onError={console.log}
                format="dddd, MMMM Do YYYY, h:mm:ss a"
                disablePast
              />

              <KeyboardDateTimePicker
                ampm={false}
                label="Select First Choice Time"
                value={selectedThirdDate}
                onChange={handleSelectedThirdDateChange}
                onError={console.log}
                format="dddd, MMMM Do YYYY, h:mm:ss a"
                disablePast
              />

              <FormControl>
                <FormControl.Label>Phone Number</FormControl.Label>
                <Input
                  variant="underlined"
                  borderColor="black"
                  mt={-3}
                  value={phoneNumber}
                  fontSize="md"
                  onChangeText={setPhoneNumber}
                />
              </FormControl>

              <FormGroup>
                <Label for="exampleFile">File</Label>
                <RInput
                  id="exampleFile"
                  name="file"
                  type="file"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />
                <FormText>upload a resume</FormText>
              </FormGroup>

              <Button onPress={onFinish} colorScheme="green" size="lg">
                FINISH
              </Button>
            </Stack>
          </VStack>
        )}
      </Center>
    </div>
  );
}
