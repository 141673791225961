import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SignupDetails from "../views/SignupDetails";
import SignupQuestions from "../views/SignupQuestions";
import ScheduleInterview from "../views/ScheduleInterview";
import SubmitSuccess from "../views/SubmitSuccess";

export default function RootLayout() {
  return (
    <Switch>
      <Route path="/signup-details" component={SignupDetails} />
      <Route path="/signup-questionaires" component={SignupQuestions} />
      <Route path="/schedule-interview" component={ScheduleInterview} />
      <Route path="/submit-success" component={SubmitSuccess}/>
      <Redirect to="/signup-details" />
    </Switch>
  );
}
