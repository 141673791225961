import React from "react";
import { useHistory } from "react-router";

import {
  Center,
  FormControl,
  CheckIcon,
  Select,
  Image,
  VStack,
  Heading,
  Stack,
  Button,
  useToast,
} from "native-base";
import { CustomInput } from "../components/CustomInput";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";
import * as moment from "moment";

// import { db } from "../firebase-config";
// import { collection, getDocs } from "firebase/firestore";

const theme = createTheme({
  palette: {
    background: {
      main: "#333",
    },
    primary: {
      main: "#333",
    },
    secondary: {
      main: "#76c9d2",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#9D399E",
    },
  },
});

export default function SignupDetails() {
  const history = useHistory();
  const toast = useToast();

  const [name, setName] = React.useState();
  const [email, setEmail] = React.useState();
  const [university, setUniversity] = React.useState();
  const [currentMajor, setCurrentMajor] = React.useState();
  const [academicStanding, setAcademicStanding] = React.useState();

  const onNext = () => {
    if (!name || !email || !university || !currentMajor || !academicStanding) {
      toast.show({
        title: "Fields cannot be empty!",
        status: "warning",
        placement: "top",
      });
      return;
    } else if (
      email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) === null
    ) {
      toast.show({
        title: "Please input a valid email!",
        status: "warning",
        placement: "top",
      });

      return;
    }

    history.push("/signup-questionaires", {
      name: name,
      email: email,
      university: university,
      currentMajor: currentMajor,
      academicStanding: academicStanding,
    });
  };

  return (
    <div
      style={{
        paddingTop: 50,
        paddingBottom: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Center mb={5}>
        <Image
          source={{
            uri: "https://www.thegenuineproject.org/wp-content/uploads/2020/08/g-logo-1.png",
          }}
          style={{
            height: 50,
            width: 200,
          }}
          resizeMode="contain"
        />
      </Center>

      <Center w="100%">
        <VStack
          bg="white.500"
          p={[2, 5, 10]}
          py={10}
          w={["95%", "90%", "80%"]}
          rounded="sm"
        >
          <Center mb={10}>
            <Heading textAlign="center">STUDENT INTERN APPLICATION</Heading>
          </Center>

          <Stack
            space={[1, 2, 5]}
            width={["90%", "85%", "50%"]}
            alignSelf="center"
          >
            <CustomInput
              label="Full Name"
              value={name}
              onChangeText={setName}
            />
            <CustomInput label="Email" value={email} onChangeText={setEmail} />
            <CustomInput
              label="University"
              value={university}
              onChangeText={setUniversity}
            />

            <CustomInput
              label="Current Major"
              value={currentMajor}
              onChangeText={setCurrentMajor}
            />

            <FormControl isRequired>
              <FormControl.Label>
                Academic Standing in Fall 2022
              </FormControl.Label>
              <Select
                selectedValue={academicStanding}
                minWidth="200"
                fontSize="md"
                accessibilityLabel="Choose your academic standing"
                placeholder="Choose your academic standing"
                _selectedItem={{
                  bg: "teal.600",
                  endIcon: <CheckIcon size="5" />,
                }}
                mt={1}
                onValueChange={setAcademicStanding}
              >
                <Select.Item label="Freshman" value="Freshman" />
                <Select.Item label="Sophomore" value="Sophomore" />
                <Select.Item label="Junior" value="Junior" />
                <Select.Item label="Senior" value="Senior" />
              </Select>
            </FormControl>
          </Stack>

          <Center mt={10}>
            <Button colorScheme="green" onPress={onNext}>
              NEXT
            </Button>
          </Center>
        </VStack>
      </Center>
    </div>
  );
}
