import { FormControl, Input } from "native-base";

export function CustomInput(props) {
  return (
    <FormControl isRequired>
      <FormControl.Label>{props.label}</FormControl.Label>
      <Input {...props} fontSize="md" />
    </FormControl>
  );
}
